const messages = {
  pwd_validator_pwd_must: "La password deve",
  pwd_validator_err_min_length: "Essere lunga almeno 8 caratteri",
  pwd_validator_err_digit: "Contenere almeno un numero",
  pwd_validator_err_uppercase: "Contenere almeno una lettera maiuscola",
  pwd_validator_err_lowercase: "Contenere almeno una lettera minuscola",
  pwd_validator_err_symbol: "Contenere almeno un carattere speciale",
  pwd_validator_err_old_password: "Non deve essere una delle precedenti 24 password",
  pwd_validator_err_keyboard_pattern: "Non deve contenere keyboard patterns (es querty)",
  pwd_validator_err_repeated_chars: "Non deve contenere caratteri ripetuti",
};

export default messages;
