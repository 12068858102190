import React, {useContext, useState} from "react";

// Services
import {NotificationsContext} from "@ui-components/Notifications";
import {api} from "@services/apiRequest";
import moment from "moment";

// Ui
import DatePicker from "@ui-components/DatePicker";
import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";
import Alert from "@ui-components/Alert";
import Input from "@ui-components/Input";
import {AddUpdateAppDialogProps, AppSchema} from "@pages/Monitoring/Monitoring.types";
import Checkbox from "@ui-components/Checkbox";
import Select from "@ui-components/Select";

export function AddUpdateAppDialog({onExit, app, people, setCcApps, statoCaringOptions}: AddUpdateAppDialogProps) {

  const {push} = useContext(NotificationsContext);
  const [data, setData] = useState<AppSchema>(app);
  const [loading, setLoading] = useState<boolean>(false);
  const isMonitored = app.des_dev_link || app.des_prod_link

  const isIncomplete = () => {
    return !data.des_app_name || !data.des_customer_name
  }

  const sendAddUpdate = () => {
    setLoading(true)
    api.post('/monitoring/cc_apps', data)
      .then(({data}) => {
        setCcApps(data)
        push({title: "Dati salvati con successo", type: "success"});
        onExit();
      })
      .catch((err) => {
        console.log(err)
        push({title: "Errore del server", type: "error"});
      })
      .finally(() => setLoading(false))
  }

  const getFromHs = () => {
    setLoading(true)
    api.get<AppSchema>(`/monitoring/hs_info/${data.deal}`)
      .then(({data: result}) => {
        let _updated: AppSchema = {...data}
        Object.entries(result).forEach(([key, value]) => {
          if (value) {
            // @ts-ignore
            _updated[key] = value
          }
        })
        setData(_updated)
        push({title: "Dati recuperati con successo", type: "success"});
      })
      .catch((err) => {
        console.log(err)
        push({title: "Impossibile recuperare i dati da HubSpot", type: "error"});
      })
      .finally(() => setLoading(false))
  }

  const customInput = (key: string, title: string, disabled?: boolean, type: string = 'text') => <div
    className="w-full">
    {/*@ts-ignore*/}
    <Input
      disabled={disabled ? isMonitored : false}
      //@ts-ignore
      value={data[key]}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setData({...data, [key]: e.target.value});
      }}
      label={title}
      required
      type={type}
      numberOptions={{
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }}
    />
  </div>

  const customDate = (key: string, title: string) => <div className="w-full">
    {/*@ts-ignore*/}
    <DatePicker
      //@ts-ignore
      value={data[key] ? new Date(data[key]) : undefined}
      onChange={(i: string) => setData({
          ...data, [key]: moment(i).format('YYYY-MM-DD')
        }
      )}
      label={title}
      placeholder="Seleziona data"
      minDate={null}
      maxDate={null}
    />
  </div>

  const customCheck = (key: string, title: string) => <div className="w-full flex flex-col items-center gap-2">
    <span className="text-sm">{title}</span>
    {/*@ts-ignore*/}
    <Checkbox
      //@ts-ignore
      checked={data[key]}
      //@ts-ignore
      onChange={() => setData({...data, [key]: !data[key]})}
    />
  </div>

  const selectPeople = (key: string, title: string, roles: string[]) => <div className="w-full">
    {/*@ts-ignore*/}
    <Select
      options={[{value: '', name: ''}]
        .concat(people.filter(i => (roles.length === 0 || roles.includes(i.role_name)) && !i.disabled)
          .sort((a, b) => a.cod_gescli.localeCompare(b.cod_gescli))
          .map(i => ({value: i.cod_gescli, name: i.cod_gescli})))}
      label={title}
      //@ts-ignore
      value={data[key]}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setData({...data, [key]: e.target.value})}
    />
  </div>

  const selectCaring = () => <div className="w-full">
    {/*@ts-ignore*/}
    <Select
      options={[{value: '', name: ''}].concat(statoCaringOptions.map(i => ({value: i, name: i})))}
      label="Stato Caring"
      //@ts-ignore
      value={data.stato_caring}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setData({...data, stato_caring: e.target.value})}
    />
  </div>

  return (
    <Modal onExit={onExit} maxWidth="sm:max-w-3xl">
      <div className="flex flex-col">
        <h1 className="font-bold sm:text-xl mr-5 mb-5">{app.id ? 'Aggiornamento app' : 'Aggiunta app'}</h1>
        <div className="flex flex-col sm:flex-row justify-between sm:gap-3">
          {customInput('des_customer_name', 'Cliente', true)}
          {customInput('des_app_name', 'Nome app', true)}
        </div>
        <div className="flex flex-col sm:flex-row justify-between sm:gap-3 items-center">
          {customInput('cod_commessa_madre', 'Commessa madre')}
          {customInput('cod_commessa_attuale', 'Commessa attuale')}
          {customInput('deal', 'ID Deal')}
          <div>
            {/*@ts-ignore*/}
            <Button
              onClick={() => getFromHs()}
              submitting={loading}
              disabled={!data.deal}>
              <span className="text-xs whitespace-nowrap">Get from HS</span>
            </Button>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-between sm:gap-3">
          {customInput('categoria', 'Categoria')}
          {selectCaring()}
          <div className="w-full flex flex-row justify-between sm:gap-3">
            {customCheck('flg_template_app', 'T. App')}
            {customCheck('flg_ticketing', 'Ticket')}
            {customCheck('flg_aggiornato_hubspot', 'HS')}
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-between sm:gap-3">
          {customInput('company_size', 'Company Size')}
          {customInput('fatturato_py', 'Fatturato PY', false, 'number')}
          {customInput('canone_attuale', 'Canone attuale', false, 'number')}
        </div>
        <div className="flex flex-col sm:flex-row justify-between sm:gap-3">
          {customDate('scadenza', 'Scadenza')}
          {customDate('scadenza_hs', 'Scadenza HS')}
          {customDate('ultimo_contatto', 'Ultimo contatto')}
        </div>
        <div className="flex flex-col sm:flex-row justify-between sm:gap-3 mt-3">
          {selectPeople('owner_customer_care', 'Customer Care', ['Head Of Customer Care', 'Customer Care Specialist'])}
          {selectPeople('pm', 'PM', ['Customer Success Manager', 'Project Manager', 'Head Of Project Manager', 'Head Of Customer Success Management'])}
        </div>
        <div className="flex flex-col sm:flex-row justify-between sm:gap-3 mt-3">
          {selectPeople('ticket_1', 'Ticket 1', [])}
          {selectPeople('ticket_2', 'Ticket 2', [])}
        </div>
        <div className="mt-3 flex flex-col sm:flex-row justify-between sm:gap-3">
          {customInput('note', 'Note', false, 'textarea')}
        </div>
      </div>
      {isIncomplete() ?
        <div className="my-5">
          {/*@ts-ignore*/}
          <Alert type="warning" text="Dati mancanti"/>
        </div> : null
      }
      <div className="w-full flex flex-col sm:flex-row gap-3 justify-between mt-5">
        {/*@ts-ignore*/}
        <Button styleType="white" onClick={onExit}>
          Annulla
        </Button>
        {/*@ts-ignore*/}
        <Button
          onClick={() => sendAddUpdate()}
          submitting={loading}
          disabled={isIncomplete()}>
          Conferma
        </Button>
      </div>
    </Modal>
  );
}
