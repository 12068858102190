// Api URL based on env
export const API_URL = "/api";

export const ROLES = [
  {value: "admin", label: "Admin"},
  {value: "user", label: "Utente base"}
]

export const CALENDAR_MODE = {
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly"
}

export const currencyStyleUsd = {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: "narrowSymbol",
  maximumFractionDigits: 0
}

export const currencyStyleEur = {
  style: 'currency',
  currency: 'EUR',
  currencyDisplay: "narrowSymbol",
  maximumFractionDigits: 0
}

export const contoMark = {
  'Prestazioni di servizi': '',
  'Delta (Prestazioni)': '',
  'Canoni': '(C)',
  'Delta (Canoni)': '(C)',
}

export const MAX_INPUT_LENGTH = 255;
export const MAX_TEXTAREA_LENGTH = 1000;
export const MAX_FAILED_LOGIN_ATTEMPTS = 6;