import React from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Label from "./Label";
import { MAX_INPUT_LENGTH } from "@utils/constants";

/**
 * @typedef {Object} Option
 *
 * @property {string} label - Label of option.
 * @property {string} value - Value of option.
 */

/**
 * @typedef {Object} NestedOptions
 *
 * @property {string} label - Label of nested options.
 * @property {Array<Option> } options - Nested options.
 */

const style =
  "mt-1 mb-2 border shadow-sm focus:ring-am-500 focus:border-am-500 block w-full sm:text-sm border-gray-300 rounded-3xl disabled:opacity-50";

const componentStyle = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: "1.3rem",
    borderColor: state.isFocused ? "black" : "transparent",
    boxShadow: "none !important",
    "&:hover": {
      borderColor: "black",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    position: "absolute",
  }),
  input: (provided, state) => ({
    input: {
      border: 0,
      outline: 0,
      boxShadow: "none !important",
      borderColor: state.isFocused ? "black" : "transparent",
    },
  }),
};

const getOptionsValues = (options) => options.map((elem) =>
  (elem["options"] !== undefined) ? {
    label: elem["name"] !== undefined ? elem.name : elem.label,
    options: getOptionsValues(elem["options"])
  } : {
    ...elem,
    label: elem["name"] !== undefined ? elem.name : elem.label
  });

/**
 * SearchSelect React componente
 *
 * @param {function} loadOptions - Function that returns a promise, which is the set of options to be used once the promise resolves
 * @param {string} label - Label of the select
 * @param {Array<Option>|NestedOptions} options - Options of select element
 * @param {function} onChange - onchange event handler
 * @param {Option} value - Current value of select element
 * @param {boolean} isMulti - Support multiple selected options
 * @param {*} CustomOption - Element to be rendered
 * @param {*} props - Rest of properties
 * @return {JSX.Element}
 * @constructor
 */
const SearchSelect = ({
                        loadOptions,
                        label,
                        options,
                        onChange,
                        value,
                        isMulti,
                        CustomOption,
                        maxLength = MAX_INPUT_LENGTH,
                        ...props
                      }) => {
  let component = null;

  const components = CustomOption
    ? { ...(props.components || {}), Option: CustomOption }
    : props.components;

  if (props.components) {
    delete props.components;
  }

  const handleInputChange = (inputValue) => {
    if (inputValue.length > maxLength) {
      return inputValue.slice(0, maxLength);
    }
    return inputValue;
  };

  if (!loadOptions) {
    component = (
      <Select
        components={components}
        styles={componentStyle}
        className={style}
        isSearchable={true}
        options={getOptionsValues(options)}
        onChange={onChange}
        value={value}
        placeholder=""
        isMulti={isMulti}
        onInputChange={handleInputChange}
        {...props}
      />
    );
  } else {
    component = (
      <AsyncSelect
        components={components}
        cacheOptions
        styles={componentStyle}
        value={value}
        loadOptions={loadOptions}
        onChange={onChange}
        className={style}
        isMulti={isMulti}
        placeholder=""
        {...props}
      />
    );
  }
  return (
    <div className="flex flex-col mb-1">
      {label ? <Label>{label}</Label> : null}
      {component}
    </div>
  );
};

export default SearchSelect;
