import React, {useState, useEffect} from "react";
import {AppSchema, MonitoringDetailProps, ByCcSchema} from "@pages/Monitoring/Monitoring.types";
import {getListItems} from "@pages/Monitoring/Detail/Detail.lib";
import SearchSelect from "@ui-components/SearchSelect";
import CardList from "@components/CardList/CardList";
import {FormatNumberOptions, useIntl} from "react-intl";
import Input from "@ui-components/Input";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import CheckboxGroup from "@ui-components/CheckboxGroup";
import RadioGroup from "@ui-components/RadioGroup";
import {MAX_INPUT_LENGTH, currencyStyleEur} from "@utils/constants";

export function MonitoringDetail({ccApps, customers, setAddEditApp, statoCaringOptions}: MonitoringDetailProps) {

  const intl = useIntl()
  const [selectedCustomers, setSelectedCustomers] = useState<{ label: string, value: string }[]>([]);
  const [selectedCaring, setSelectedCaring] = useState<{ label: string, value: string }[]>([]);
  const [ccOwner, setCcOwner] = useState<{ label: string, value: string }[]>([]);
  const [searchKey, setSearchKey] = useState<string | undefined>(undefined);
  const [filterData, setFilterData] = useState<AppSchema[]>(ccApps);
  const [offset, setOffset] = useState<number>(0);
  const [flags, setFlags] = useState<{ tapp: boolean[], ticket: boolean[], hs: boolean[] }>({
    tapp: [], ticket: [], hs: []
  })
  const [orderBy, setOrderBy] = useState<'id' | 'scadenza' | 'ultimo_contatto'>('id')

  const [byCc, setByCc] = useState<ByCcSchema[]>([])

  useEffect(() => {
    let tmp = ccApps
    if (selectedCustomers.length > 0) {
      tmp = tmp.filter(i =>
        selectedCustomers.map(j => j.value).includes(i.des_customer_name))
    }
    if (selectedCaring.length > 0) {
      tmp = tmp.filter(i =>
        selectedCaring.map(j => j.value).includes((i.stato_caring || '-')))
    }
    if (ccOwner.length > 0) {
      tmp = tmp.filter(i =>
        ccOwner.map(j => j.value).includes(i.owner_customer_care || '-'))
    }
    if (flags.tapp.length === 1) {
      tmp = tmp.filter(i => i.flg_template_app === flags.tapp[0])
    }
    if (flags.ticket.length === 1) {
      tmp = tmp.filter(i => i.flg_ticketing === flags.ticket[0])
    }
    if (flags.hs.length === 1) {
      tmp = tmp.filter(i => i.flg_aggiornato_hubspot === flags.hs[0])
    }
    const _search = (searchKey || '').toLowerCase()
    if (_search.length > 2) {
      tmp = tmp.filter(i =>
        i.des_customer_name.toLowerCase().includes(_search) ||
        i.des_app_name?.toLowerCase().includes(_search) ||
        i.categoria?.toLowerCase().includes(_search) ||
        i.note?.toLowerCase().includes(_search) ||
        i.pm?.toLowerCase().includes(_search) ||
        i.ticket_1?.toLowerCase().includes(_search) ||
        i.ticket_2?.toLowerCase().includes(_search)
      )
    }
    setFilterData(tmp)

  }, [selectedCaring, flags, ccOwner, searchKey, ccApps, selectedCustomers]);

  useEffect(() => {
    let res: any = {}
    filterData.forEach((i) => {
      if (i.owner_customer_care) {
        if (!res[i.owner_customer_care]) {
          res[i.owner_customer_care] = {
            num_app: 1,
            amt_ricavi: i.canone_attuale || 0,
            owner_customer_care: i.owner_customer_care.split('.')[0]
          }
        } else {
          res[i.owner_customer_care]['num_app'] += 1
          res[i.owner_customer_care]['amt_ricavi'] += i.canone_attuale || 0
        }
      }
    })
    setByCc(Object.values(res))
  }, [filterData]);

  const getCCOptions = () => {
    let uniqueCc: string[] = []
    ccApps.forEach(i => {
      if (i.owner_customer_care && !uniqueCc.includes(i.owner_customer_care)) {
        uniqueCc.push(i.owner_customer_care)
      }
    })
    return uniqueCc.sort().map(i => ({label: i, value: i}))
  }

  const checkBoxFilter = (title: string, key: 'tapp' | 'ticket' | 'hs') => <CheckboxGroup
    title={title}
    description={null}
    horizontal={true}
    options={[
      {value: true, name: 'Sì'},
      {value: false, name: 'No'}
    ]}
    values={flags[key]}
    onChange={(value: boolean) => {
      if (flags[key].indexOf(value) >= 0) {
        setFlags({...flags, [key]: [...flags[key].filter((v) => v !== value)]});
      } else {
        setFlags({...flags, [key]: [...flags[key], value]});
      }
    }}
  />

  return (
    <>
      <div className="w-full flex flex-col sm:flex-row justify-center gap-2 sm:gap-5 my-5">
        <div className="w-full">
          {/*@ts-ignore*/}
          <SearchSelect
            label="Owner CC"
            isMulti
            value={ccOwner}
            options={getCCOptions()}
            onChange={(e: { value: string, label: string }[]) => setCcOwner(e)}
          />
        </div>
        <div className="w-full">
          {/*@ts-ignore*/}
          <SearchSelect
            label="Cliente"
            isMulti
            value={selectedCustomers}
            options={customers.map(i => ({label: i.des_customer.toUpperCase(), value: i.des_customer}))}
            onChange={(e: { value: string, label: string }[]) => setSelectedCustomers(e)}
          />
        </div>
        <div className="w-full">
          {/*@ts-ignore*/}
          <SearchSelect
            label="Stato caring"
            isMulti
            value={selectedCaring}
            options={statoCaringOptions.map(i => ({label: i, value: i}))}
            onChange={(e: { value: string, label: string }[]) => setSelectedCaring(e)}
          />
        </div>
        <div className="w-full mt-1">
          <Input
            value={searchKey}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchKey(e.target.value);
              if (setOffset) {
                setOffset(0);
              }
            }}
            label={
              <div className='flex flex-row gap-2'>
                <MagnifyingGlassIcon className='h-4 w-4 mt-0.5 -mb-3'/>
                <span>Cerca</span>
              </div>
            }
            name={undefined}
            validation={undefined}
            required={undefined}
            errorMessage={undefined}
            showCalendar={undefined}
            onClear={undefined}
            maxLength={MAX_INPUT_LENGTH}
          />
        </div>
      </div>
      <div className="w-full -mt-2 sm:-mt-4 mb-2 flex flex-row justify-center items-center gap-5">
        <div className="w-full flex flex-row justify-around">
          {checkBoxFilter('T.App', 'tapp')}
          {checkBoxFilter('Ticket', 'ticket')}
          {checkBoxFilter('HS', 'hs')}
        </div>
        <div className="flex w-full flex-col">
          <span className="text-sm font-medium text-gray-700 -mb-3 whitespace-nowrap">Ordina per</span>
          {/*@ts-ignore*/}
          <RadioGroup
            id="chose_order"
            horizontal
            options={[
              {value: "id", name: "Inserimento"},
              {value: "scadenza", name: "Scadenza"},
              {value: "ultimo_contatto", name: "Ultimo contatto"}
            ]}
            currentValue={orderBy}
            onChange={(value: 'id' | 'scadenza' | 'ultimo_contatto') => {
              setOrderBy(value)
            }}
          />
        </div>
      </div>
      <div className="mb-5 rounded-3xl pb-2 px-5 flex items-center justify-center">
        <table className="text-center text-sm divide-y">
          <thead>
          <tr>
            {['Owner', 'App', 'Ricavi'].map(c =>
              <th scope="col" className="py-2 px-10">
                <span>{c}</span>
              </th>
            )}
          </tr>
          </thead>
          <tbody className="divide-y">
          {byCc
            .sort((a, b) => a.owner_customer_care.localeCompare(b.owner_customer_care))
            .map(i => <tr>
              <td className="py-1">{i.owner_customer_care}</td>
              <td>
                <b>{i.num_app}</b>
                <i className="ml-2">
                  ({intl.formatNumber(i.num_app / byCc.reduce((acc, i) => acc + i.num_app, 0), {style: 'percent', maximumFractionDigits: 0})})
                </i>
              </td>
              <td>
                <b>{intl.formatNumber(i.amt_ricavi, currencyStyleEur as FormatNumberOptions)}</b>
                <i className="ml-2">
                  ({intl.formatNumber(i.amt_ricavi / byCc.reduce((acc, i) => acc + i.amt_ricavi, 0), {style: 'percent', maximumFractionDigits: 0})})
                </i>
              </td>
              </tr>
            )
          }
          </tbody>
        </table>

      </div>
      <CardList
        limit={10}
        offset={offset}
        setOffset={setOffset}
        total={filterData.length}
        items={getListItems(filterData, orderBy, intl, setAddEditApp)}
        rowClassNames={['sm:grid', 'sm:grid-cols-9', 'gap-2']}
        pagination='frontend'
      />
    </>
  );
}
