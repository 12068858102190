import { useEffect, useState } from 'react';
import { api } from '@services/apiRequest';

export function useGetActiveUserRole() {
    const [role, setRole] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchActiveUserRole = async () => {
            try {
                const response = await api.get('/roles/active_role');
                setRole(response.data);
            } catch (error) {
                console.error('Failed to fetch active user role:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchActiveUserRole();
    }, []);

    return { role, loading };
}