import React, {useCallback, useContext, useState} from "react";

// Services
import moment from "moment";
import apiRequest from "../../services/apiRequest";
import {NotificationsContext} from "../../ui-components/Notifications";

import Input from "../../ui-components/Input";
import Select from "../../ui-components/Select";
import Modal from "../../ui-components/Modal";
import Button from "../../ui-components/Button";
import DatePicker from "../../ui-components/DatePicker";

const getInit = (selectedYear) => {
  const idUnique = String(new Date().getTime()).slice(5)
  return {
    id_documento: Number(idUnique),
    id_riga: 1,
    status: "",
    year: selectedYear,
    month: -1,
    dat_arrivo_ordine: undefined,
    dat_vinta_hubspot: undefined,
    cod_commessa: "",
    des_conto: "",
    des_commessa: "",
    des_cliente: "",
    cod_pm: "",
    amt_importo: undefined,
    num_mesi_ripartizione: undefined,
    tranche: {}
  }
}

function AddRevenueDialog({open, toggleOpen, entry, setEntry, selectedYear, statusOptions, intl, pmList}) {
  const [loading, setLoading] = useState(false);
  const {push} = useContext(NotificationsContext);
  const [row, setRow] = useState(getInit(selectedYear));

  const _onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        if (!row.id_documento || !row.status || !row.month || !row.cod_commessa || !row.des_commessa || !row.des_cliente || !row.cod_pm
          || !row.amt_importo || (row.status === 'RINNOVO' && (!row.num_mesi_ripartizione || !row.des_conto))) {
          push({
            title: "Dati mancanti",
            type: "error",
          });
          return;
        }

        setLoading(true);
        const cleanedRow = {...row,
          month: `${selectedYear}-${(Number(row.month) + 1).toString().padStart(2, '0')}-01`,
          des_conto: row.des_conto || 'Nuova commessa',
          dat_arrivo_ordine: row.dat_arrivo_ordine ? moment(row.dat_arrivo_ordine).format('YYYY-MM-DD') : undefined,
          dat_vinta_hubspot: row.dat_vinta_hubspot ? moment(row.dat_vinta_hubspot).format('YYYY-MM-DD') : undefined,
        }
        console.log(cleanedRow)
        const newRow = await apiRequest.post("/economics/entry", cleanedRow);
        const key = `${newRow.id_documento}_${newRow.id_riga}`
        setEntry({...entry, [key]: newRow});
        setRow(getInit(selectedYear));
        toggleOpen();
        push({title: "Elemento aggiunto correttamente", type: "success"});
      } catch (err) {
        console.log(err)
        if(row.status === 'DELTA'){
          push({title: "Delta già presente, per inserirne uno nuovo cancellare il pre-esistente!", type: "error"});
        } else {
          push({title: "Errore imprevisto", type: "error"});
        }
      } finally {
        setLoading(false);
      }
    },
    [row, entry] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div>
      <Modal opened={open} onExit={toggleOpen}>
        <div className="m-8">
          <h3 className="text-center text-xl font-extrabold text-gray-900">
            Aggiungi il rinnovo di un canone o una commessa entrante
          </h3>
        </div>
        <form id="add-revenue-form" onSubmit={_onSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-5 mb-4">
            <div>
              <Select
                options={[{value: undefined, name: ""}]
                  .concat(statusOptions.filter(i => i !== 'ESOLVER').map(i => ({value: i, name: i})))}
                value={row.status}
                onChange={(e) => setRow({...row, status: e.target.value})}
                label="Stato"
              />
            </div>
            <div>
              <Select
                options={[{value: -1, name: ""}]
                  .concat([...Array(12).keys()].map(i => ({
                    value: i,
                    name: intl.formatDate(new Date(selectedYear, i, 1), {month: "short", year: "2-digit"})
                  })))}
                value={row.month}
                onChange={(e) => setRow({...row, month: e.target.value})}
                label="Mese (ingresso deal)"
              />
            </div>
          </div>
          {row.status === 'RINNOVO' &&
            <div className="mb-4">
              <Select
                options={[{value: undefined, name: ""}]
                  .concat(['Canoni per licenze', 'Canoni per manutenzione', 'Canoni per hosting']
                    .map(i => ({value: i, name: i})))
                }
                value={row.des_conto}
                onChange={(e) => setRow({...row, des_conto: e.target.value})}
                label="Tipo canone"
              />
            </div>
          }
          <div className="my-3 text-xxs">
            N.B. Il codice della commessa deve corrispondere a quanto verrà riportato da Esolver, la descrizione e il cliente sono liberi.
            In automatico il record da Esolver sostituirà quello inserito manualmente.
          </div>
          <div>
            <Input
              value={row.cod_commessa}
              onChange={(e) => {
                setRow({...row, cod_commessa: e.target.value})
              }}
              label="Codice commessa"
              placeholder="Inserisci il codice nella forma YYYY_###"
              type="text"
              errorMessage="Inserisci il codice della commessa"
              required
            />
          </div>
          <div>
            <Input
              value={row.des_commessa}
              onChange={(e) => {
                setRow({...row, des_commessa: e.target.value})
              }}
              label="Descrizione commessa"
              type="text"
              errorMessage="Inserisci una descrizione per la commessa"
              required
            />
          </div>
          <div>
            <Input
              value={row.des_cliente}
              onChange={(e) => {
                setRow({...row, des_cliente: e.target.value})
              }}
              label="Cliente"
              type="text"
              errorMessage="Inserisci il nome del cliente"
              required
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-5 mb-4">
            <DatePicker
              minDate={null}
              maxDatw={null}
              value={row.dat_arrivo_ordine}
              onChange={(e) => setRow({...row, dat_arrivo_ordine: e})}
              label="Data ordine arrivato"
              placeholder="Seleziona la data"
            />
            <DatePicker
              minDate={null}
              maxDatw={null}
              value={row.dat_vinta_hubspot}
              onChange={(e) => setRow({...row, dat_vinta_hubspot: e})}
              label="Data vinta in hubspot"
              placeholder="Seleziona la data"
            />
          </div>
          <div className="mb-4">
            <Select
              options={[{value: undefined, name: ""}].concat(pmList.map(i => ({value: i, name: i.replace('.', ' ')})))}
              value={row.cod_pm}
              onChange={(e) => setRow({...row, cod_pm: e.target.value})}
              label="Project Manager"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-10">
            <div className="mt-4">
              <Input
                value={row.amt_importo}
                onChange={(e) => {
                  setRow({...row, amt_importo: parseFloat(e.target.value)})
                }}
                label="Importo fatturazione"
                placeholder="Inserisci importo"
                type="number"
                numberOptions={{
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }}
              />
            </div>
            {row.status === 'RINNOVO' &&
              <div className="mt-4">
                <Input
                  value={row.num_mesi_ripartizione}
                  onChange={(e) => {
                    setRow({...row, num_mesi_ripartizione: parseFloat(e.target.value)})
                  }}
                  label="Mesi ripartizione canone"
                  placeholder="Inserisci # mesi"
                  type="number"
                  numberOptions={{
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }}
                />
              </div>
            }
          </div>
          <div className="mt-6 flex items-end justify-end">
            <Button submitting={loading} type="submit">
              Aggiungi
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default AddRevenueDialog;
