import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { useIntl, FormattedMessage } from "react-intl";

// Services
import AuthenticationService from "../../../services/AuthenticationService";
import { NotificationsContext } from "../../Notifications";
import { api } from "@services/apiRequest";
import ErrorBoundary from "../../ErrorBoundary";

// Components
import AddUserDialog from "./AddUserDialog";
import UsersTable from "./UsersTable";

// UI
import { UserPlusIcon } from "@heroicons/react/24/solid";
import { PageHeading } from "../../Container";
import Button from "../../Button";
import { useGetActiveUserRole } from "../Roles/Hooks/useGetActiveUserRole";

function Users() {
  const intl = useIntl();
  const [users, setUsers] = useState();
  const [showNewUserDialog, setShowNewUserDialog] = useState(false);
  const scopes = AuthenticationService.getUserScopes();
  const { push } = useContext(NotificationsContext);
  const { role: activeUserRole, loading } = useGetActiveUserRole();

  useEffect(() => {
    const fetchUsers = () => {
      api
        .get('/users')
        .then(({ data: users }) => {
          setUsers(
            (users || []).reduce(
              (usersObj, user) => _.set(usersObj, [user.id], user),
              {}
            )
          );
        })
        .catch(() => {
          setUsers({})
          push({
            title: intl.formatMessage({ id: "server_error" }),
            type: "error",
          });
        });
    };

    if (!users) {
      fetchUsers();
    }
    // eslint-disable-next-line
  }, [users, push]);

  const toggleNewUserDialog = () => setShowNewUserDialog(!showNewUserDialog);
  const canCreateUser = scopes.indexOf("user:create") >= 0;

  return (
    <>
      <PageHeading
        title={intl.formatMessage({ id: "users" })}
        contentActions={
          canCreateUser && (activeUserRole !== null && activeUserRole.val_hierarchy < 0)? (
            <Button
              variant="contained"
              color="primary"
              onClick={toggleNewUserDialog}
              data-cy="add-user"
            >
              <UserPlusIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              <FormattedMessage id="add" />
            </Button>
          ) : null
        }
      />

      <ErrorBoundary from="users-table">
        {!loading ? (
          <UsersTable users={users} setUsers={setUsers} push={push} activeUserRole={activeUserRole}/>
        ): <div>Loading...</div>}
      </ErrorBoundary>
      {canCreateUser ? (
        <ErrorBoundary from="users-add-dialog">
          <AddUserDialog
            open={showNewUserDialog}
            toggleOpen={toggleNewUserDialog}
            users={users}
            setUsers={setUsers}
          />
        </ErrorBoundary>
      ) : null}
    </>
  );
}

export default Users;
