import jwtDecode from "jwt-decode";
import { api, refreshApiRequest } from "@services/apiRequest";

export const REFRESH_TOKEN_ENDPOINT_URL = "/auth/refresh-token";
export const LOGIN_ENDPOINT_URL = "/auth/login";
export const OAUTH2_LOGIN_ENDPOINT_URL = "/oauth2/login";
export const LOGIN_URL = "/login";

export class AuthenticationService {
  static AUTH_STORAGE_PATH = "logged_user";
  // Deprecated
  // AUTH_TIME IS NOT USED ANYMORE, we respect exp field in jwt
  static AUTH_TIME = 2 * 3600; // 2h respect jwt format in seconds

  static getUserData() {
    const authObj = JSON.parse(localStorage.getItem(this.AUTH_STORAGE_PATH));

    if (authObj) {
      return authObj;
    }

    return null;
  }

  static logout_and_redirect(redirectCallback) {
    this.logout();
    if (window.location.pathname !== LOGIN_URL) {
      this.logout();
      if (typeof redirectCallback === "function") {
        redirectCallback(LOGIN_URL);
      } else {
        window.location.href = LOGIN_URL;
      }
    }
  }

  static getUserScopes() {
    const token = this.getAuthToken();

    if (token) {
      const { scopes } = jwtDecode(token);
      return scopes;
    }

    return [];
  }

  static getPasswordExpiration() {
    const token = this.getAuthToken();

    if (token) {
      const { password_expiration } = jwtDecode(token);
      return Date.parse(password_expiration);
    }

    return null;
  }

  static getNowEpoch() {
    return Date.now() / 1000; // respect jwt format in seconds
  }

  static isTokenExpired() {
    const authObj = JSON.parse(localStorage.getItem(this.AUTH_STORAGE_PATH));
    if (!authObj) {
      return false;
    }
    if (!authObj.expires) {
      return false;
    }
    if (authObj && authObj.expires_at) {
      return authObj.expires_at < this.getNowEpoch();
    }
    return true;
  }

  static isRefreshTokenExpired() {
    const authObj = JSON.parse(localStorage.getItem(this.AUTH_STORAGE_PATH));
    if (!authObj) {
      return false;
    }
    if (!authObj.expires) {
      return false;
    }
    if (authObj && authObj.expires_at_refresh) {
      return authObj.expires_at_refresh < this.getNowEpoch();
    }
    return true;
  }

  static getAuthToken() {
    const authObj = JSON.parse(localStorage.getItem(this.AUTH_STORAGE_PATH));

    if (authObj && authObj?.access_token) {
      return authObj.access_token;
    }
    return undefined;
  }

  static getRefreshToken() {
    const authObj = JSON.parse(localStorage.getItem(this.AUTH_STORAGE_PATH));

    if (authObj && authObj?.refresh_token) {
      return authObj.refresh_token;
    }

    return undefined;
  }

  static getExpiresAt(token, expires = true) {
    if (!token) {
      return undefined;
    }
    const now = this.getNowEpoch();
    
    const { exp } = jwtDecode(token);
    const expires_at = exp || (expires ? now + this.AUTH_TIME : -1);

    return expires_at;
  }

  /**
   *
   * @param {string} access_token
   * @param {string} [refresh_token]
   * @param {object} userData
   * @param {boolean} expires
   */
  static setAuthToken(access_token, refresh_token, userData, expires = true) {

    const expires_at = this.getExpiresAt(access_token, expires);
    const expires_at_refresh = this.getExpiresAt(refresh_token, expires);

    const authObj = {
      ...userData,
      access_token,
      refresh_token,
      expires,
      expires_at,
      expires_at_refresh,
    };

    localStorage.setItem(this.AUTH_STORAGE_PATH, JSON.stringify(authObj));
  }

  static deleteAuthToken() {
    localStorage.removeItem(this.AUTH_STORAGE_PATH);
  }

  static async login(username, password) {
    const data = new FormData();
    data.append("username", username);
    data.append("password", password);

    const {data: { access_token, refresh_token, user_data }} = await api.post(
      LOGIN_ENDPOINT_URL,
      data,
      {
        publicRequest: true,
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    if (access_token) {
      // refresh_token can be null
      this.setAuthToken(access_token, refresh_token, user_data);
    } else {
      this.logout_and_redirect();
      return;
    }

  }

  static getExpiringPasswordDays() {
    let password_expiration = this.getPasswordExpiration()
    let days_left = Math.floor((password_expiration - new Date())/ (1000*60*60*24))
    return days_left
  }

  static async getOauth2Url() {
    return api
      .get(OAUTH2_LOGIN_ENDPOINT_URL, { publicRequest: true })
      .then(({data}) => {
        let url = new URL("authorize", data["oauth_authority"]);
        const searchParams = new URLSearchParams({
          client_id: data["client_id"],
          scope: data["scope"],
          response_type: data["response_type"],
          redirect_uri: data["redirect_uri"],
          prompt: "select_account",
        });
        url.search = searchParams.toString();
        return url;
      });
  }

  static logout() {
    this.deleteAuthToken();
  }

  static isUserAuthenticated() {
    const access_token = this.getAuthToken();
    return !!access_token;
  }

  static authHeader() {
    if (this.isUserAuthenticated())
      return {
        Authorization: `Bearer ${this.getAuthToken()}`,
      };

    return false;
  }

  static async refreshToken() {
    const refresh_token = AuthenticationService.getRefreshToken();
    if (!refresh_token) {
      return null;
    }
    const data = new FormData();
    data.append("refresh_token", refresh_token);
    data.append("grant_type", "refresh_token");
    const response = await refreshApiRequest.post(
      REFRESH_TOKEN_ENDPOINT_URL,
      data,
      {
        publicRequest: true,
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    const {
      access_token: new_access_token,
      refresh_token: new_refresh_token,
      user_data,
    } = response.data;
    if (new_access_token) {
      this.setAuthToken(new_access_token, new_refresh_token, user_data);
    }
    return new_access_token;
  }
}

export default AuthenticationService;
